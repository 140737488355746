import type { FC } from 'react';
import React, { useCallback, Fragment } from 'react';
import { useMutation } from '@apollo/react-hooks';
import type { WrappedComponentProps } from 'react-intl';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import { token } from '@atlaskit/tokens';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';
import WatchIcon from '@atlaskit/icon/glyph/watch';
import { N500 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { ButtonItem } from '@atlaskit/menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { ShortcutEvent } from '@confluence/shortcuts';

import { SpaceWatchMutation, SpaceUnwatchMutation } from '../ActionButtonMutations.graphql';
import { FilledIconButton, UnfilledIconButton } from '../PresentationalComponents';
import type { AnalyticsOptions, StarMutationArgs } from '../SpaceStar/SpaceStar';

export type SpaceWatchButtonProps = {
	spaceId: string;
	isWatched: boolean;
	onWatch?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	onUnwatch?: (e: MouseEvent | KeyboardEvent | ShortcutEvent) => void;
	analytics?: AnalyticsOptions;
};

type InMenuProp = {
	inMenu?: boolean;
};

const i18n = defineMessages({
	Watch: {
		id: 'action-buttons.space.watch',
		defaultMessage: 'Watch space',
		description: 'Tooltip message to Watch the given space.',
	},
	Unwatch: {
		id: 'action-buttons.space.unwatch',
		defaultMessage: 'Unwatch space',
		description: 'Tooltip message to remove Watch status from the given space.',
	},
});

const WatchComponent: FC<SpaceWatchButtonProps & InMenuProp & WrappedComponentProps> = ({
	spaceId,
	isWatched,
	onWatch,
	onUnwatch,
	analytics,
	inMenu = false,
	intl,
}) => {
	const [watchSpace] = useMutation<StarMutationArgs>(SpaceWatchMutation, {
		optimisticResponse: {
			__typename: 'Mutation',
			watchSpace: {
				__typename: 'WatchSpacePayload',
				space: {
					__typename: 'Space',
					id: spaceId,
					currentUser: {
						__typename: 'SpaceUserMetadata',
						isWatched: true,
					},
				},
			},
		},
	});

	const [unWatchSpace] = useMutation<StarMutationArgs>(SpaceUnwatchMutation, {
		optimisticResponse: {
			__typename: 'Mutation',
			unwatchSpace: {
				__typename: 'WatchSpacePayload',
				space: {
					__typename: 'Space',
					id: spaceId,
					currentUser: {
						__typename: 'SpaceUserMetadata',
						isWatched: false,
					},
				},
			},
		},
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(
		(e: any) => {
			if (isWatched) {
				onUnwatch && onUnwatch(e);
			} else {
				onWatch && onWatch(e);
			}

			const mutation = isWatched ? unWatchSpace : watchSpace;
			void mutation({
				variables: { spaceId },
			});
			if (analytics) {
				const { source, containerId, attributes } = analytics;
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: isWatched ? 'unwatched' : 'watched',
						actionSubject: 'space',
						source,
						attributes,
						...(containerId && { containerId }),
					},
				}).fire();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[spaceId, isWatched, analytics, watchSpace, unWatchSpace, createAnalyticsEvent],
	);

	const watchTooltip = isWatched ? i18n.Unwatch : i18n.Watch;

	const watchIcon = isWatched ? (
		<WatchFilledIcon label="" primaryColor={token('color.icon', N500)} />
	) : (
		<WatchIcon label="" secondaryColor="inherit" />
	);

	const watchIconButton = isWatched ? (
		<FilledIconButton onClick={handleClick} data-testid="actions-watched">
			<WatchFilledIcon
				label={intl.formatMessage(i18n.Unwatch)}
				primaryColor={token('color.icon', N500)}
			/>
		</FilledIconButton>
	) : (
		<UnfilledIconButton onClick={handleClick} data-testid="actions-unwatched">
			<WatchIcon label={intl.formatMessage(i18n.Watch)} secondaryColor="inherit" />
		</UnfilledIconButton>
	);

	return (
		<Fragment>
			{inMenu ? (
				<ButtonItem testId="watch-menu-item" iconBefore={watchIcon} onClick={handleClick}>
					<FormattedMessage {...watchTooltip} />
				</ButtonItem>
			) : (
				<Tooltip content={<FormattedMessage {...watchTooltip} />} hideTooltipOnMouseDown>
					{watchIconButton}
				</Tooltip>
			)}
		</Fragment>
	);
};

export const SpaceWatchButton = injectIntl(WatchComponent);
