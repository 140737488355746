import type { FC } from 'react';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import StarIcon from '@atlaskit/icon/glyph/star';
import { ButtonItem } from '@atlaskit/menu';

import { StarSelectedIcon } from '@confluence/icons/entry-points/StarSelectedIcon';

import type { MenuItemProps } from '../PageStar/PageStarMenuItem';

import type { SpaceStarProps } from './SpaceStar';
import { SpaceStar } from './SpaceStar';

/**
 * Menu button that allows users to star or unstar a space.
 *
 * Renders as a menu button with an icon. Typically used in menus (e.g. Space actions menu, Space directory overflow menu)
 */
export const SpaceStarMenuItem: FC<MenuItemProps & SpaceStarProps> = ({
	isDisabled,
	cssFn,
	...props
}) => {
	const TooltipI18n = props.isStarred ? i18n.Unstar : i18n.Star;

	const starIcon = props.isStarred ? (
		<StarSelectedIcon label="" />
	) : (
		<StarIcon label="" secondaryColor="inherit" />
	);

	return (
		<SpaceStar {...props}>
			{({ toggle: toggleStar }) => (
				<ButtonItem
					testId="star-menu-item"
					// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
					cssFn={cssFn}
					isDisabled={isDisabled}
					iconBefore={starIcon}
					onClick={toggleStar}
				>
					<FormattedMessage {...TooltipI18n} />
				</ButtonItem>
			)}
		</SpaceStar>
	);
};

const i18n = defineMessages({
	Star: {
		id: 'action-buttons.space.star.menu.item',
		defaultMessage: 'Star space',
		description:
			'Text for unfilled star menu item which indicates that the space is unstarred & stars the space when clicked',
	},
	Unstar: {
		id: 'action-buttons.space.unstar.menu.item',
		defaultMessage: 'Unstar space',
		description:
			'Text for filled star menu item which indicates that the space is starred & unstars the space when clicked',
	},
});
